import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaSave } from "react-icons/fa";
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { ClipLoader } from 'react-spinners';
import { getName, getUserName } from '../../../Redux/selecters';
import InvApiService from "../../../INVENTORY/services/apiService"
import ApiService from "../../services/apiServices"
import SaleRetItm from './itemGrid';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
const SaleReturnCreate = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [messageData, setMessageData] = useState([]);
    const [authorizationData, setAuthorizationData] = useState([]);
    const [ItemsData, setQuotationData] = useState([]);
    const [SelectedWareHouse, setSelectedWareHouse] = useState([]);
    const [SelectedPO, setSelectedPO] = useState([]);
    const [SelectedGrn, setSelectedGrn] = useState();
    const [SelectedSupplier, setSelectedSupplier] = useState([]);
    const [SelectedSupplierId, setSelectedSupplierID] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [dNList, setDNList] = useState([]);
    const [saleOrderData, setSOData] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const [SupplierTab, setSupplierTab] = useState(false);
    const [PurchaseTab, setPurchaseTab] = useState(true);
    const userName = useSelector(getUserName);
    const [loader, setLoader] = useState(false);
    const [saleReturnNum, setSaleReturnNum] = useState()
    const item = location.state;
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const InvapiService = new InvApiService();
    const apiService = new ApiService();
    const [attachmentModal, setAttachmentModal] = useState(false);
    const [attachmentList, setAttachmentList] = useState([{ id: Date.now(), fileName: '', remarks: '' }]);
    const handleClose = () => setAttachmentModal(false);
    const handleOpen = () => setAttachmentModal(true);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    }
    useEffect(() => {
        getCurrency()
    }, [])
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    }
    const handleMessage = (data) => {
        setMessageData(data);
        console.log(data, 'reqData')

    }
    const handleQuotationData = (data) => {
        setQuotationData(data);
        console.log(data, 'reqData')
    };
    const handleAuthorization = (data) => {
        setAuthorizationData(data);
        console.log(data, 'reqData')
    }
    const [formData, setFormData] = useState({
        PRNo: "",
        prNo: "",
        grnStatus: "",
        remarks: "",
        InvoiceNo: "",
        refNo: "",
        InvoiceDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        pOrderNo: "",
        TaxInvoice: "",
        Property: "",
        Department: "",
        Outlet: "",
        Currency: "",
        exchangeRate: "",
        deliveryPoint: "",
        PurchaseOrderDate: getCurrentLocalDatetime(),
        contactPerson: "",
        contatNo: "",
        taskCode: "",
        email: '',
        subcatname: "",
        location: ''
    })
    const handlednNoChange = (selectedOption) => {
        setSelectedPO(selectedOption);
        setSelectedSupplier({ label: selectedOption.value.vendorName, value: selectedOption?.value })
        setSelectedSupplierID({ label: selectedOption.value.vendorId, value: selectedOption?.value })
        setSelectedGrn(selectedOption?.value?.sOrderNo)
        setFormData({ ...formData, PurchaseOrderDate: formatDateToDatetimeLocal(selectedOption.value.modifiedOn) });
        setSelectedWareHouse({ label: selectedOption.value.warehouse, value: selectedOption.value.warehouse })
        setSOData(selectedOption.value.deliveryNoteDetails)
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'PurchaseOrderDate' || name === 'deliveryDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const getCurrency = async () => {
        let param = `?isActive=true`
        let docParam = `?TypeOfDoc=SR&DocId=Sale Return`
        let DocId = await InvapiService.getApiParamater('GET_DOC_ID', docParam)
        let res = await InvapiService.getApiParamater('GET_CURRENCY_MASTER', param)
        let DnParam = `?Status=1`
        let DrnListList = await apiService.getApiParamater('GET_DN', DnParam)
        setDNList(DrnListList.data.result)
        const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
        if (filteredDenominations) {
            setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
        }
        setFormData({ exchangeRate: filteredDenominations?.conversionRate })
        setSaleReturnNum(DocId?.data.result[0].docId);
    }
    const handleBackClick = () => {
        window.history.back();
    }
    const CreateSO = async () => {
        setLoader(true)
        if (ItemsData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }
        else if (!SelectedSupplier?.label) {
            toast.warning("Please Select Customer");
            setLoader(false)
            return;
        }
        const totalAmount = ItemsData.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
        try {
            const currentDatetimeUTC = new Date().toISOString();
            const SODetailsData = ItemsData.map((item, index) => ({
                prodDate: currentDatetimeUTC,
                expDate: currentDatetimeUTC,
                convToInvUOM: item?.convRate,
                dnDetailId: 0,
                ssrno: index + 1,
                sReturnNo: saleReturnNum,
                vatPerc: item?.vat,
                vatDesc: item?.vatDec,
                vatAmount: item.vatAmount,
                vatUnitPrice: item.vatAmount / parseInt(item.qty),
                vatBaseAmount: item.vatAmount,
                srno: index + 1,
                itemCode: item.ItemNo || item.recordId,
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseInt(item.qty),
                unitPrice: parseFloat(item.unitPrice),
                amount: parseFloat(item.amount),
                baseAmount: parseFloat(item.amount),
            }));
            const userData = {
                dnNo: SelectedPO?.label,
                invoiceNo: formData.InvoiceNo,
                invoiceDate: currentDatetimeUTC,
                invoiceRef: formData.refNo,
                sOrderNo: SelectedGrn,
                deliveryDate: currentDatetimeUTC,
                transportMode: SelectedSupplierId?.label,
                transporter: SelectedSupplier?.label,
                deliveryType: "Sale Return",
                warehouse: SelectedWareHouse?.label,
                currencyCode: SelectedCurrency?.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                amount: parseFloat(totalAmount),
                baseAmount: parseFloat(totalAmount),
                approvalUser: userName,
                status: 1,
                approvalDate: currentDatetimeUTC,
                remarks: formData?.remarks,
                modifiedBy: userName,
                modifiedOn: currentDatetimeUTC,
                sReturnNo: saleReturnNum,
                saleReturnDetails: SODetailsData
            };
            const response = await apiService.post("CREATE_SALE_RETURN", userData);
            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "SO",
                    docId: saleReturnNum,
                    department: "IT",
                    // company: Userdata?.company || "Qfinity",
                };
                const AttachmentData = {
                    docType: "SR",
                    docNo: saleReturnNum ? saleReturnNum.toString() : saleReturnNum,
                    fileName: "IT",
                };
                const docBody = {
                    docId: "Sale Return",
                    typeOfDoc: "SR",
                };

                // const attachment = await apiService.post("CREATE_ATTACHMENT", AttachmentData);
                // const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const docRes = await InvapiService.put("UPDATE_DOC_ID", docBody);
                setLoader(false)
                toast.success(`${saleReturnNum} Created successfully`);
                navigate("/Sales/Return")
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PO:", error);
        }
    }
    const handleFileSelect = (event, id) => {
        const file = event.target.files[0];
        if (file) {
            setAttachmentList((prevList) => [
                ...prevList.map((attachment) =>
                    attachment.id === id ? { ...attachment, fileName: file.name, file: file } : attachment
                ),
                { id: Date.now(), fileName: '', remarks: '' },
            ]);
        }
    };
    const handleView = (file) => {
        if (file && file instanceof File) {
            setPreviewImage(URL.createObjectURL(file));
            setPreviewModal(true);
        } else {
            console.error("No valid file to preview");
        }
    };
    const handleDelete = (id) => {
        const updatedList = attachmentList.filter((attachment) => attachment.id !== id);
        setAttachmentList(updatedList);
    };
    return (
        <div className='px-4 pt-3'>
            <div className='d-flex justify-contant-between '>
                <h5 className='col mainInvHead'>Sales Return</h5>
                <div className='d-flex justify-content-end gap-3'>
                    <div>
                        {location.state ? (
                            <>
                                {loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <>{item?.eye === true ? "" :
                                        <button className='mainbtn ' type="submit" ><FaSave className='center mt-1 mx-2' /><div>Update</div></button>
                                    }</>
                                }
                            </>
                        ) : (
                            <>
                                {loader === true ?
                                    <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                                    <>{item?.eye === true ? "" :
                                        <button className='mainbtn ' onClick={CreateSO} type="submit" ><FaSave className='center mt-1 mx-2' /><div>Save</div></button>
                                    }</>
                                }
                            </>
                        )}
                    </div>
                    {item?.eye === true ? "" :
                        <button className='mainbtnRed'><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>}
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setPurchaseTab(!PurchaseTab)}>
                    <div className='h6 pt-1'>DN Details</div>
                </div>
                <div className={`slide-content ${PurchaseTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group justify-content-between row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="saleReturnNum" className="form-label  pt-1 start">Sales Return No</label>
                                <input
                                    className="form-Input w-70"
                                    value={saleReturnNum}
                                    name='saleReturnNum'
                                    disabled
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="catcode" className="form-label  pt-1 start">Date </label>
                                <input className='form-Input w-70' type='datetime-local' min={currentDatetimeUTC}
                                    name="deliveryDate" disabled={item?.eye === true ? true : false}
                                    value={formData.deliveryDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Location</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isDisabled
                                    value={SelectedWareHouse}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">DN No *</label>
                                <Select isDisabled={item?.eye === true ? true : false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={SelectedPO}
                                    options={dNList?.map((po) => ({ value: po, label: po?.dnNo }))}
                                    onChange={(selectedOption) => handlednNoChange(selectedOption)}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNo" className="form-label  pt-1 start">Invoice Reg No</label>
                                <input
                                    type="number" disabled={item?.eye === true ? true : false}
                                    className="form-Input w-70"
                                    id="InvoiceNo"
                                    value={formData.InvoiceNo}
                                    onChange={handleInputChange}
                                    name="InvoiceNo"
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceDate" className="form-label  pt-1 start">Ref NO</label>
                                <input
                                    type="number" disabled={item?.eye === true ? true : false}
                                    className="form-Input w-70"
                                    id="refNo"
                                    value={formData.refNo}
                                    onChange={handleInputChange}
                                    name="refNo"
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="PurchaseOrderDate" className="form-label  pt-1 start">DN Date</label>
                                <input disabled
                                    type="datetime"
                                    className="form-Input w-70"
                                    id="PurchaseOrderDate"
                                    value={moment(formData.PurchaseOrderDate).format('DD-MMM-YYYY')}
                                    name="PurchaseOrderDate"
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="pOrderNo" className="form-label  pt-1 start">SO No</label>
                                <input disabled
                                    type="text"
                                    className="form-Input w-70"
                                    id="pOrderNo"
                                    value={SelectedGrn}
                                    name="pOrderNo"
                                    readOnly
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="remarks" className="form-label  pt-1 start">Remarks</label>
                                <input disabled={item?.eye === true ? true : false}
                                    name="remarks"
                                    type='text'
                                    className="form-Input w-70"
                                    id="remarks"
                                    value={formData.remarks}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section mb-2">
                <div className="d-flex mt-3 cursor-pointer slide-header gap-4"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Customer Details</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="PropertyGroup" className="form-label  pt-1 start">Customer</label>
                                <Select isDisabled
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={SelectedSupplier}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Customer ID</label>
                                <Select isDisabled
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={SelectedSupplierId}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Property" className="form-label  pt-1 start">Currency</label>
                                <Select isDisabled={item?.eye === true ? true : false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    isSearchable={false}
                                    value={SelectedCurrency}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="EXRate" className="form-label  pt-1 start">EX Rate</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    disabled
                                    onWheel={(e) => e.target.blur()}
                                    id="exchangeRate"
                                    value={formData.exchangeRate}
                                    onChange={handleInputChange}
                                    name="exchangeRate"
                                />
                            </div>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className="col-2 brderbox center" onClick={() => handleOpen()} >
                                {'Attachment'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`cost-center-section`} >
                <div className="mt-3 cursor-pointer slide-header">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Message
                        </div>
                    </div>
                </div>
            </div>
            <div className={`slide-content open`}>
                <div className={`grid-1 border-box2 p-3`}>
                    {gridVisibility[0] && (
                        <SaleRetItm onQuotationData={handleQuotationData} itemData={ItemsData} SelectedSO={SelectedPO} saleOrderData={saleOrderData} />
                    )}
                    {/* {gridVisibility[1] && (
                        // <AuthorizationPurchaseReturn handleAuthorization={handleAuthorization}
                        //     usersDetails={usersDetails}
                        //     setUser={setUser}
                        //     itemData={POData} />
                    )} */}
                    {gridVisibility[2] && (
                        <div className="grid-3 border">
                            {/* <MessageGrid handleMessage={handleMessage} /> */}
                        </div>
                    )}
                </div>
            </div>
            <div>
                <Modal show={attachmentModal} size="lg" onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Attachments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col d-flex justify-content-between">
                            <label htmlFor="docNp" className="form-label  pt-2 start">Document No</label>
                            <input disabled value={saleReturnNum} className="form-Input w-70" />
                        </div>
                        <div className="pt-2">
                            <table>
                                <thead className="dynoTable">
                                    <tr>
                                        <th>File Name</th>
                                        <th>Remarks</th>
                                        <th>Upload</th>
                                        <th>View</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attachmentList?.map((attachment, index) => (
                                        <tr key={attachment.id}>
                                            <td className='m-0 p-0'><input className='p-0' type='text' disabled value={attachment.fileName} style={{ width: "320px" }} /></td>
                                            <td>
                                                <input type="text" value={attachment.remarks}
                                                    onChange={(e) => {
                                                        const updatedList = attachmentList?.map((item) =>
                                                            item.id === attachment.id ? { ...item, remarks: e.target.value } : item
                                                        );
                                                        setAttachmentList(updatedList);
                                                    }}
                                                    style={{ width: '345px' }}
                                                />
                                            </td>
                                            <td>
                                                <button className='mainbtn ' onClick={() => document.getElementById(`fileInput-${attachment.id}`).click()} type="submit"><div>Upload</div></button>
                                                <input
                                                    type="file" accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileSelect(e, attachment.id)}
                                                    id={`fileInput-${attachment.id}`}
                                                />
                                            </td>
                                            <td><button className='mainbtnRed ' onClick={() => handleView(attachment.file)} disabled={!attachment.fileName}>
                                                <div>View</div></button></td>
                                            <td>
                                                <button onClick={() => handleDelete(attachment.id)} disabled={!attachment.fileName} className='mainbtnSecondary'>
                                                    <div>Delete</div></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='mainbtn '>Attach</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={previewModal} size="lg" onHide={() => setPreviewModal(false)}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Image Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {previewImage && <img src={previewImage} alt={previewImage} style={{ width: '100%', objectFit: 'cover' }} />}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default SaleReturnCreate