import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiServices';
import Select from 'react-select';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
// import { hasPermission } from "../../services/roleService"
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from 'react-select/async';
import { ClipLoader } from 'react-spinners';
import Pagination from '../../../INVENTORY/Pages/pagination/pagination';
import { getName, getUserName } from '../../../Redux/selecters';

const Posting = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [postedData, setPostedData] = useState(null);
    const [selectedPost, setSelectedPost] = useState();
    const [modalData, setModalData] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loader, setLoader] = useState(false);
    const [DocTerm, setDocTerm] = useState({ value: 'Doc Type', label: 'Doc Type' });
    const [pageSize, setPageSize] = useState(20);
    const [permissions, setPermissions] = useState([]);
    const [pagination, setPagination] = useState();
    const userName = useSelector(getName);
    const [GlModal, setGlModal] = useState(false);
    const onOpenModal = () => setGlModal(true);
    const onCloseModal = () => setGlModal(false);
    const apiService = new ApiService();
    const currentDatetimeUTC = new Date().toISOString();
    const todayDate = new Date().toISOString().substr(0, 10);
    let navigate = useNavigate();
    const DocFilter = [
        { value: 'Delivery Note', label: 'Delivery Note' },
        { value: 'Sale Return', label: 'Sale Return' },
        { value: 'Sale Invoice', label: 'Invoice' },
    ];
    const [formData, setFormData] = useState({
        DocNo: "",
        RefNo: '',
        AmountFrom: '',
        AmountTo: "",
        fromDate: '',
        toDate: '',
    });
    useEffect(() => {
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        setPermissions(storedPermissions);
    }, []);
    // const checkPermission = (moduleName, action) => {
    //     return hasPermission(moduleName, action, permissions);
    // };
    // const canEditItemMaster = checkPermission('Posting', 'edit');
    // const canCreateItemMaster = checkPermission('Posting', 'create');
    const canEditItemMaster = true;
    const canCreateItemMaster = true;
    const getItems = async () => {
        try {
            const params = new URLSearchParams();
            const defaultFromDate = moment().subtract(1, 'months').format("DD-MMM-YY");
            const defaultToDate = moment().add(1, 'months').format("DD-MMM-YY");
            const fromDate = formData.fromDate ? moment(formData.fromDate).format("DD-MMM-YYYY") : defaultFromDate;
            const toDate = formData.toDate ? moment(formData.toDate).format("DD-MMM-YYYY") : defaultToDate;
            params.append('PageNo', pagination?.currentPage || 1);
            params.append('PageSize', pageSize || 20);
            params.append('DocType', DocTerm.value);
            if (formData.DocNo) {
                params.append('DocNo', formData.DocNo || '');
            }
            params.append('From', fromDate);
            params.append('To', toDate);
            params.append('AmountFrom', formData.AmountFrom || 0);
            params.append('AmountTo', formData.AmountTo || 0);
            const res = await apiService.getApiParamater('GET_SALES_POSTING',`?${params.toString()}`);
            const sortedData = res.data.result.sort((a, b) => {
                const dateA = moment(a.docdate, "DD MMM YYYY");
                const dateB = moment(b.docdate, "DD MMM YYYY");
                if (dateA.isAfter(dateB)) return -1;
                if (dateA.isBefore(dateB)) return 1;
                const docNoA = parseInt(a.docno.split('/')[1], 10);
                const docNoB = parseInt(b.docno.split('/')[1], 10);
                return docNoB - docNoA;
            });
            setPagination(res.data.paginationData)
            setRowData(sortedData || []);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    }
    const PostData = async (data) => {
        try {
            setLoading(true);
            setPostedData(data)
            const params = new URLSearchParams();
            params.append('DocType', data.doctype);
            params.append('DocNo', data.docno);
            // const res = await apiService.getApiParamater('GET_GL_POSTING', `?${params.toString()}`);
            // setSelectedPost(res.data.result)
            const requestBody = {
                docNo: data.docno,
                mode: data.doctype,
                user: userName
            }
            debugger
            const response = await apiService.post("POST_SALES", requestBody);
            toast.success(`${data.docno} Posted Successfully`)
            setLoading(false);
            // if (res?.data.statusCode === 200) {
                // if (response.data.statusCode === 201) {
                    getItems()
                    setLoading(false);
                // }
            // }
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const handleGlChange = async (data) => {
        const params = new URLSearchParams();
        params.append('DocType', data.doctype);
        params.append('DocNo', data.docno);
        const res = await apiService.getApiParamater('GET_GL_ACC_POST', `?${params.toString()}`);
        const filteredData = res?.data?.result?.filter(row => row.debit > 0);
        setModalData(filteredData?.reverse())
        onOpenModal()
    }
    const Comparison = [
        { headerName: 'Doc Type', field: 'doctype', filter: true, floatingfilter: true, suppressMovable: true, width: 170 },
        { headerName: 'Doc No', field: 'docno', filter: true, floatingfilter: true, suppressMovable: true, width: 170 },
        { headerName: 'Ref No', field: 'refno', filter: true, floatingfilter: true, suppressMovable: true, width: 170 },
        { headerName: 'Doc Date', field: 'docdate', filter: true, floatingfilter: true, suppressMovable: true, width: 170 },
        { headerName: 'Invoice No', field: 'invoiceno', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { headerName: 'Invoice Ref', field: 'invoiceref', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { headerName: 'Currency Code', field: 'currCode', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        { headerName: 'Amount', field: 'amount', filter: true, floatingfilter: true, suppressMovable: true, width: 150 },
        {
            headerName: 'Change GL', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 150,
            cellRenderer: function (params) {
                return (
                    <>
                        {['Sale Return', 'Adjustment', 'Sale Return'].includes(params?.data?.doctype) ? "" :
                            <button style={{ color: '#1032E7', textDecoration: 'underline' }} onClick={() => handleGlChange(params?.data)} class="btn">Change GL</button>
                        }
                    </>
                );
            }
        },
        {
            headerName: 'Action', field: 'id', cellRenderer: PrinterBtn,
            cellRendererParams: {
                PostData: PostData,
                canEdit: canEditItemMaster,
                loading: loading,
                postedData: postedData,
                clicked: function (field) {
                    // handleShow(field)
                },
            }, suppressMovable: true, width: 150
        }
    ];
    const handleBackClick = () => {
        window.history.back();
    };
    const handleReset = () => {
        setFormData({
            DocNo: "",
            RefNo: '',
            AmountFrom: '',
            AmountTo: "",
            fromDate: todayDate,
            toDate: todayDate,
        });
        setDocTerm({ value: 'Doc Type', label: 'Doc Type' })
    };
    const handleDocFilter = (selectedOption) => {
        localStorage.setItem('selectedOptionLabel', selectedOption.label);
        setDocTerm(selectedOption);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'fromDate' || name === 'toDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const onCellClicked = async (event) => {
        if (event.colDef.field === 'docno' || 'refno' || 'doctype' || 'docdate' || 'invoiceno' || 'currCode' || 'amount') {
            const clickedRowData = event.data;
            const params = new URLSearchParams();
            params.append('DocType', clickedRowData.doctype);
            params.append('DocNo', clickedRowData.docno);
            debugger
            // const res = await apiService.getApiParamater('GET_GL_POSTING', `?${params.toString()}`);
            // setSelectedPost(res.data.result)
        }
    };
    const handleCGSAccountChange = (selectedOption, index) => {
        debugger
        const updatedData = modalData.map((row, i) =>
            i === index ? {
                ...row,
                accountName: selectedOption.label,
                accountCode: selectedOption.value.accountCode
            } : row
        );
        setModalData(updatedData);
    };
    const loadOptions = async (inputValue, callback) => {
        const options = await fetchAccountData(inputValue, 1);
        callback(options);
    };
    const loadMoreOptions = async () => {
        if (!hasMore || loader) return;
        setPage(prevPage => prevPage + 1);
        await fetchAccountData("", page + 1);
    };
    useEffect(() => {
        fetchAccountData("", 1);
    }, []);

    const fetchAccountData = async (inputValue = "", pageNumber = 1) => {
        if (loader) return;
        setLoader(true);
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', pageNumber || 1);
            params.append('PageSize', 20);
            params.append('AccountName', inputValue || '');
            const response = await apiService.getApiParamater('GET_ACCOUNTS', `?${params.toString()}`);
            const fetchedAccounts = response.data.result.map(account => ({
                label: account.accountName,
                value: account,
            }));
            if (fetchedAccounts.length < 20) setHasMore(false);
            setAccounts(prevAccounts => [...prevAccounts, ...fetchedAccounts]);
            setLoader(false);
            return fetchedAccounts;
        } catch (error) {
            console.error("Error fetching account data:", error);
            setLoader(false);
            return [];
        }
    };
    const UpdatePost = async () => {
        try {
            for (const item of modalData) {
                debugger
                const param = {
                    docNo: item.docNo,
                    inventoryAccountName: item.accountName,
                    inventoryAccountCode: item.accountCode,
                    srno: item.srno,
                    itemCode: item.itemCode,
                    docType: DocTerm.value
                };
                const res = await apiService.put('UPDATE_GL_ACC_POST', param);
                if (res.data.statusCode === 200 || 201) {
                    const params = new URLSearchParams();
                    params.append('DocType', DocTerm.value);
                    params.append('DocNo', item.docno);
                    const GlRes = await apiService.getApiParamater('GET_GL_POSTING', `?${params.toString()}`);
                    setSelectedPost(GlRes.data.result)
                    onCloseModal()
                }
            }
        } catch (error) {
            console.error('Update Error:', error);
        }
    };
    return (
        <div className='px-4 pb-5'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Posting</h5>
                <div className='d-flex gap-3'>
                    <button className='mainbtnGry ' type="submit" onClick={getItems}><FaSave className='center mt-1 mx-2' /><div>Search</div></button>
                    <button className='mainbtnRed' onClick={handleReset} ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className='row d-flex justify-content-between py-3'>
                <div className='col'>
                    <Select
                        value={DocTerm}
                        options={DocFilter}
                        onChange={handleDocFilter}
                        className=" p-0"
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            padding: 9
                        })}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                    />
                </div>
                <div className='col'>
                    <input
                        type='date'
                        className="form-Input"
                        name='fromDate'
                        placeholder='from Date'
                        value={formData?.fromDate || moment().subtract(1, 'months').format('YYYY-MM-DD')}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='col'>
                    <input
                        type='date' className="form-Input" name='toDate'
                        placeholder='To Date'
                        value={formData?.toDate || todayDate}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='col'>
                    <input
                        type='text'
                        className="form-Input" name='DocNo'
                        placeholder='Doc No'
                        value={formData?.DocNo}
                        onChange={handleInputChange}
                    />
                </div>.
                <div className='col'>
                    <input
                        type='number'
                        className="form-Input" name='AmountFrom'
                        placeholder='Amount From'
                        value={formData?.AmountFrom}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='col'>
                    <input
                        type='number'
                        className="form-Input" name='AmountTo'
                        placeholder='Amount To'
                        value={formData?.AmountTo}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className='gridshad'>
                <div className="ag-theme-quartz" style={{ height: 500 }}>
                    <AgGridReact
                        getRowStyle={(params) => {
                            if (params.node.rowIndex % 2 === 0) {
                                return { background: '#F0EDF5' };
                            } else {
                                return { background: '#fff' };
                            }
                        }}
                        rowData={rowData}
                        columnDefs={Comparison}
                        pagination={false}
                        rowSelection={'single'}
                        suppressExcelExport={true}
                        suppressRowClickSelection={true}
                        onCellClicked={onCellClicked}
                    />
                </div>
                <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
            </div>
            <div className='gridshad mt-5'>
                <div className='row p-2 p-0 m-0' style={{ backgroundColor: '#6E5199', color: '#fff', fontWeight: 500 }}>
                    <div className='col-2'>Account Code</div>
                    <div className='col-2'>Date</div>
                    <div className='col-2'>Account Name</div>
                    <div className='col-2'>Cost Center 1</div>
                    <div className='col-2'>Cost Center 2</div>
                    <div className='col-1'>Debit</div>
                    <div className='col-1'>Credit</div>
                </div>
                {selectedPost?.map((row, index) => (
                    <div className='row p-2 p-0 m-0'>
                        <div className='col-2'>{row?.accountCode}</div>
                        <div className='col-2'>{moment(currentDatetimeUTC).format("DD-MMM-YYYY")}</div>
                        <div className='col-2'>{row?.accountName}</div>
                        <div className='col-2'>{row?.costCenter1 || "---"}</div>
                        <div className='col-2'>{row?.costCenter2 || "---"}</div>
                        <div className='col-1'>{row?.debit}</div>
                        <div className='col-1'>{row?.credit}</div>
                    </div>
                ))}
            </div>
            <Modal size="lg"
                show={GlModal} onHide={onCloseModal}
                onRequestClose={onCloseModal}
                contentLabel='Email Modal'
                style={customStyles} closeButton
            >
                <Modal.Header closeButton>
                    <Modal.Title className='center'>Change GL</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className='dynoTable'>
                        <thead>
                            <tr>
                                <th scope="col">Item code</th>
                                <th scope="col">Item Description</th>
                                <th scope="col">Specification</th>
                                <th scope="col">Acc Code</th>
                                <th scope="col">Acc Name</th>
                                <th scope="col">Debit</th>
                                <th scope="col">Credit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modalData?.map((row, index) => (
                                <tr key={index}>
                                    <td className='m-0 p-0'>
                                        <input className='p-1' disabled value={row.itemCode} style={{ width: "130px" }} />
                                    </td>
                                    <td className='m-0 p-0'>
                                        <input className='p-1' disabled value={row.itemDescription} style={{ width: "160px" }} />
                                    </td>
                                    <td className='m-0 p-0'>
                                        <input className='p-1' disabled value={row.specification} style={{ width: "130px" }} />
                                    </td>
                                    <td className='m-0 p-0'>
                                        <input className='p-1' disabled value={row.accountCode} style={{ width: "130px" }} />
                                    </td>
                                    <td className='m-0 p-0'>
                                        <AsyncSelect
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                padding: 9
                                            })}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999, suppressMovable: true, width: '180px' }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                    color: state.isFocused ? '#000 ' : '#000',
                                                    ':hover': {
                                                        backgroundColor: '#F5F5F5',
                                                        color: '#000 ',
                                                    },
                                                }),
                                            }}
                                            className="w-100 p-0"
                                            isDisabled={!canCreateItemMaster}
                                            value={{ value: row.accountName, label: row.accountName }}
                                            onChange={(selectedOption) => handleCGSAccountChange(selectedOption, index)}
                                            loadOptions={loadOptions}
                                            defaultOptions={accounts}
                                            cacheOptions
                                            onMenuScrollToBottom={loadMoreOptions}
                                        />
                                    </td>
                                    <td className='m-0 p-0'>
                                        <input className='p-1' disabled value={row.debit} style={{ width: "130px" }} />
                                    </td>
                                    <td className='m-0 p-0'>
                                        <input className='p-1' disabled value={row.credit} style={{ width: "130px" }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer className='end p-3'>
                    <button className='mainbtn' onClick={UpdatePost}>Update</button>
                </Modal.Footer>
            </Modal >
        </div >
    );
};
class PrinterBtn extends Component {
    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    constructor(props) {
        super(props);
        this.btnPostHandler = this.btnPostHandler.bind(this);
    }

    btnPostHandler() {
        const { data, PostData } = this.props;
        PostData(data);
    }

    render() {
        const { canEdit } = this.props;
        const { loading } = this.props;
        const { data } = this.props;
        const { postedData } = this.props;
        return (
            <div className='center'>
                {loading === true && postedData?.docno === data?.docno ?
                    <button className="gridbtn p-0"><ClipLoader color="#ffffff" size={25} /></button>
                    :
                    <button onClick={this.btnPostHandler} className="gridbtn p-0">Post</button>
                }
            </div>
        )
    }
}
export default Posting;
const customStyles = {
    content: {
        width: '30%', // Set the desired width
        height: '75%', // Set the desired 
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        right: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Center both vertically and horizontally
    },
};