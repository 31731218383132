import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import "../INVENTORY/Assets/styles/addmenu.css"
import "../INVENTORY/Assets/styles/style.css"
import { getRole } from '../Redux/selecters/index'
import PlainLayout from '../POS/component/pages/SideBar/plainLayout';
// import Login from '../POS/component/pages/user/Login';
import Signup from '../POS/component/pages/user/signup';
import DashBoard from '../INVENTORY/Pages/dashBoard/dashboard';
import SaleOrder from '../SalesTrading/Pages/salesOrder/salesOrder';
import CreateSaleOrder from '../SalesTrading/Pages/salesOrder/createSaleOrder';
import { CurrencyProvider } from '../INVENTORY/Layouts/currencyContext';
import DRNListing from '../SalesTrading/Pages/DRN/DRNList';
import CreateDRN from '../SalesTrading/Pages/DRN/creareDRN';
import CustomerInvoiceListing from '../SalesTrading/Pages/customerInvoice/customerInvoice';
import CreateCustomerInv from '../SalesTrading/Pages/customerInvoice/createCustomerInv';
import QuotationListing from '../SalesTrading/Pages/Quotation/quotationList';
import CreateQuotation from '../SalesTrading/Pages/Quotation/createQuotation';
import MainSalesLayout from '../SalesTrading/Layouts/mainLayout';
import QuotationView from '../SalesTrading/Pages/Quotation/quotationView';
import SalesInvoicePrint from '../SalesTrading/Pages/customerInvoice/print';
import SalesReturn from '../SalesTrading/Pages/salesReturn/salesReturn';
import SaleReturnCreate from '../SalesTrading/Pages/salesReturn/saleReturnCreate';
import QuotationPrint from '../SalesTrading/Pages/Quotation/print';
import SaleOrderPrint from '../SalesTrading/Pages/salesOrder/print';
import DNPrint from '../SalesTrading/Pages/DRN/print';
import SaleOrderView from '../SalesTrading/Pages/salesOrder/view';
import DnView from '../SalesTrading/Pages/DRN/view';
import CustomerInvView from '../SalesTrading/Pages/customerInvoice/invoiceview';
import Posting from '../SalesTrading/Pages/posting/posting';
import InventoryLogin from '../INVENTORY/Components/login/login';
import QuotationRevision from '../SalesTrading/Pages/Quotation/revision';
import SaleReturnPrint from '../SalesTrading/Pages/salesReturn/returnPrint';

const AppRoutes = () => {
  const role = useSelector(getRole);
  const [userRole, setUserRole] = useState(role);
  useEffect(() => {
    // navigate('/')
  }, [userRole]);
  return (
    <CurrencyProvider>
      <Routes>
        {!userRole && <>
          {/* Customer routes */}
          <Route path="/login" element={<PlainLayout><InventoryLogin /></PlainLayout>} />
          <Route path="/signup" element={<PlainLayout><Signup /></PlainLayout>} />
        </>
        }
        <Route path="/" element={<MainSalesLayout><DashBoard /></MainSalesLayout>} />
        <>
          {/* Sales Routes */}
          <Route path="/Sales/SaleOrder" element={<MainSalesLayout><SaleOrder /></MainSalesLayout>} />
          <Route path="/Sales/SaleOrder/create" element={<MainSalesLayout><CreateSaleOrder /></MainSalesLayout>} />
          <Route path="/Sales/SaleOrder/Print" element={<MainSalesLayout><SaleOrderPrint /></MainSalesLayout>} />
          <Route path="/Sales/SaleOrder/:id/:id" element={<MainSalesLayout><SaleOrderView /></MainSalesLayout>} />
          <Route path="/Sales/DRN" element={<MainSalesLayout><DRNListing /></MainSalesLayout>} />
          <Route path="/Sales/DRN/create" element={<MainSalesLayout><CreateDRN /></MainSalesLayout>} />
          <Route path="/Sales/DRN/Print" element={<MainSalesLayout><DNPrint /></MainSalesLayout>} />
          <Route path="/Sales/DRN/:id/:id" element={<MainSalesLayout><DnView /></MainSalesLayout>} />
          <Route path="/Sales/Customer/Invoice" element={<MainSalesLayout><CustomerInvoiceListing /></MainSalesLayout>} />
          <Route path="/Sales/Customer/Invoice/Create" element={<MainSalesLayout><CreateCustomerInv /></MainSalesLayout>} />
          <Route path="/Sales/Customer/Invoice/Print" element={<MainSalesLayout><SalesInvoicePrint /></MainSalesLayout>} />
          <Route path="/Sales/Customer/Invoice/:id/:id" element={<MainSalesLayout><CustomerInvView /></MainSalesLayout>} />
          <Route path="/Sales/Quotation" element={<MainSalesLayout><QuotationListing /></MainSalesLayout>} />
          <Route path="/Sales/Quotation/Create" element={<MainSalesLayout><CreateQuotation /></MainSalesLayout>} />
          <Route path="/Sales/Quotation/:id/:id" element={<MainSalesLayout><QuotationView /></MainSalesLayout>} />
          <Route path="/Sales/Quotation/Print" element={<MainSalesLayout><QuotationPrint /></MainSalesLayout>} />
          <Route path="/Sales/Quotation/Revision/:id/:id" element={<MainSalesLayout><QuotationRevision /></MainSalesLayout>} />
          <Route path="/Sales/Return" element={<MainSalesLayout><SalesReturn /></MainSalesLayout>} />
          <Route path="/Sales/Return/Create" element={<MainSalesLayout><SaleReturnCreate /></MainSalesLayout>} />
          <Route path="/Sales/Return/:id/:id" element={<MainSalesLayout><SaleReturnPrint /></MainSalesLayout>} />
          <Route path="/Sales/Posting" element={<MainSalesLayout><Posting /></MainSalesLayout>} />
        </>
      </Routes>
    </CurrencyProvider >
  );
};

export default AppRoutes;