import React, { useEffect, useState, useRef } from 'react';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import ItemsGrid from './itemGrid';
import InvApiService from "../../../INVENTORY/services/apiService"
import ApiService from "../../services/apiServices"
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getName } from '../../../Redux/selecters';
import Modal from 'react-bootstrap/Modal';
const CreateSaleOrder = () => {
    const [loader, setLoader] = useState(false);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const [SOTab, setSOTab] = useState(true);
    const [CustomerTab, setCustomerTab] = useState(false);
    const [CostTab, setCostTab] = useState(false);
    const [ItemsData, setRequisitionData] = useState([]);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [activeTab, setActiveTab] = useState(0);
    const InvapiService = new InvApiService();
    const apiService = new ApiService();
    const [SoNum, setSONum] = useState();
    const [cost1, setCost1] = useState([]);
    const [cost2, setCost2] = useState([]);
    const [cost3, setCost3] = useState([]);
    const [cost4, setCost4] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [Payment, setPayment] = useState([]);
    const [payments, setPayments] = useState([]);
    const [isVatExcluded, setIsVatExcluded] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [hasMore2, setHasMore2] = useState(true);
    const [hasMore3, setHasMore3] = useState(true);
    const [hasMore4, setHasMore4] = useState(true);
    const [hasMoreCustomer, setHasMoreCustomer] = useState(true);
    const [load, setLoad] = useState(false);
    const [load2, setLoad2] = useState(false);
    const [load3, setLoad3] = useState(false);
    const [load4, setLoad4] = useState(false);
    const [loaderCustomer, setLoadCustomer] = useState(false);
    const [page, setPage] = useState(1);
    let navigate = useNavigate();
    const location = useLocation();
    const userName = useSelector(getName);
    const itemData = location.state;
    const [attachmentModal, setAttachmentModal] = useState(false);
    const [attachmentList, setAttachmentList] = useState([{ id: Date.now(), fileName: '', remarks: '' }]);
    const handleClose = () => setAttachmentModal(false);
    const handleOpen = () => setAttachmentModal(true);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    useEffect(() => {
        const item = location.state;
        const getDocId = async () => {
            try {
                setIsVatExcluded(item?.vatInclusive === false ? true : false)
                let customerlist = await apiService.get('GET_CUSTOMER')
                let paymentlist = await InvapiService.get('GET_EPAYMENT')
                let cost1 = await InvapiService.get('GET_COST_ONE')
                let cost2 = await InvapiService.get('GET_COST_TWO')
                let cost3 = await InvapiService.get('GET_COST_THREE')
                let cost4 = await InvapiService.get('GET_COST_FOUR')
                const customer = customerlist?.data?.result?.find(dep => dep.customer_Name === item?.vendorName)
                const paymentTerm = paymentlist?.data?.result?.find(dep => dep.description === item?.paymentTerms)
                const costCenter1 = cost1?.data?.result?.find(dep => dep.description === item?.costCenter1)
                const costCenter2 = cost2?.data?.result?.find(dep => dep.description === item?.costCenter2)
                const costCenter3 = cost3?.data?.result?.find(dep => dep.description === item?.costCenter3)
                const costCenter4 = cost4?.data?.result?.find(dep => dep.description === item?.costCenter4)
                const DocumentDate = item?.quotationDate ? formatDateToDatetimeLocal(item?.quotationDate) : getCurrentLocalDatetime();
                const deliveryDate = item?.deliveryDate ? formatDateToDatetimeLocal(item?.deliveryDate) : getCurrentLocalDatetime();
                setPayment({ label: paymentTerm?.description, value: paymentTerm })
                setFormData({
                    DocumentDate: DocumentDate,
                    deliveryDate: deliveryDate,
                    quotationNo: item?.quotationNo,
                    SellerName: item?.sellerName,
                    ContactNo: item?.contatNo,
                    deliveryPoint: item?.deliveryPoint,
                    currencyCode: item?.currencyCode,
                    location: item?.location,
                    contactPerson: item?.contactPerson,
                    remarks: item?.remarks,
                    SupplierContact: customer?.mainContactNo,
                    costCenter1: { label: costCenter1?.description, value: costCenter1 },
                    costCenter2: { label: costCenter2?.description, value: costCenter2 },
                    costCenter3: { label: costCenter3?.description, value: costCenter3 },
                    costCenter4: { label: costCenter4?.description, value: costCenter4 },
                    RequisitionType: item?.quotType,
                    customerNum: customer?.mainContactNo,
                    exchangeRate: item?.exchangeRate,
                    Description: item?.description,
                    customerId: { label: customer?.customer_no, value: customer },
                    customerName: { label: customer?.customer_Name, value: customer },
                    paymentTerm: { label: paymentTerm?.description, value: paymentTerm },
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        if (item?.quotationNo) {
            getDocId()
        }
    }, [location?.state]);
    useEffect(() => {
        getDocId()
        fetchCost1("", 1);
        fetchCost2("", 1);
        fetchCost3("", 1);
        fetchCost4("", 1);
        fetchCustomer("", 1);
    }, []);
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    }
    const [formData, setFormData] = useState({
        PayOrderNo: "",
        remarks: "",
        DocumentDate: getCurrentLocalDatetime(),
        SupplierContact: "",
        deliveryDate: getCurrentLocalDatetime(),
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        quotationNo: "",
        RequisitionType: "",
        ContactNo: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        CostCentre5: "",
        currencyCode: "",
        customerNum: "",
        exchangeRate: "",
        Description: "",
        SellerName: "",
        customerId: "",
        customerName: "",
        OrderType: [],
        paymentTerm: [],
        SupplierDep: [],
    })
    const handleBackClick = () => {
        window.history.back();
    }
    const getDocId = async () => {
        try {
            let param = `?isActive=true`
            let docParam = `?TypeOfDoc=SO&DocId=SO`
            let DocId = await InvapiService.getApiParamater('GET_DOC_ID', docParam)
            const response = await InvapiService.get('GET_EPAYMENT');
            setPayments(response?.data?.result);
            let res = await InvapiService.getApiParamater('GET_CURRENCY_MASTER', param)
            const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
            if (filteredDenominations) {
                setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
            }
            setFormData({ exchangeRate: filteredDenominations?.conversionRate })
            setSONum(DocId?.data.result[0].docId);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const loadCost1 = async (inputValue, callback) => {
        const options = await fetchCost1(inputValue, 1);
        callback(options);
    }
    const loadMoreCost1 = async () => {
        if (!hasMore || load) return;
        setPage(prevPage => prevPage + 1);
        await fetchCost1("", page + 1);
    }
    const fetchCost1 = async (inputValue = "", pageNumber = 1) => {
        if (load) return;
        setLoad(true);
        try {
            const params = new URLSearchParams();
            params.append('PageNo', pageNumber || 1);
            params.append('PageSize', 20);
            params.append('Description', inputValue || '');
            const response = await InvapiService.getApiParamater('GET_COST_ONE', `?${params.toString()}`);
            const fetchedCost1 = response.data.result.map(account => ({
                label: account.description,
                value: account,
            }));
            if (fetchedCost1.length < 20) setHasMore(false);

            setCost1(prevAccounts => {
                const staticNoneOption = { label: 'None', value: null };
                if (pageNumber === 1) {
                    return [staticNoneOption, ...fetchedCost1];
                }
                return [...prevAccounts, ...fetchedCost1];
            });
            setLoad(false);
            return fetchedCost1;
        } catch (error) {
            console.error("Error fetching account data:", error);
            setLoad(false);
            return [];
        }
    };
    const loadCost2 = async (inputValue, callback) => {
        const options = await fetchCost2(inputValue, 1);
        callback(options);
    }
    const loadMoreCost2 = async () => {
        if (!hasMore2 || load2) return;
        setPage(prevPage => prevPage + 1);
        await fetchCost2("", page + 1);
    }
    const fetchCost2 = async (inputValue = "", pageNumber = 1) => {
        if (load2) return;
        setLoad2(true);
        try {
            const params = new URLSearchParams();
            params.append('PageNo', pageNumber || 1);
            params.append('PageSize', 20);
            params.append('Description', inputValue || '');
            const response = await InvapiService.getApiParamater('GET_COST_TWO', `?${params.toString()}`);
            const fetchedCost2 = response.data.result.map(account => ({
                label: account.description,
                value: account,
            }));
            if (fetchedCost2.length < 20) setHasMore2(false);

            setCost2(prevAccounts => {
                const staticNoneOption = { label: 'None', value: null };
                if (pageNumber === 1) {
                    return [staticNoneOption, ...fetchedCost2];
                }
                return [...prevAccounts, ...fetchedCost2];
            });
            setLoad2(false);
            return fetchedCost2;
        } catch (error) {
            console.error("Error fetching account data:", error);
            setLoad2(false);
            return [];
        }
    };
    const loadCost3 = async (inputValue, callback) => {
        const options = await fetchCost3(inputValue, 1);
        callback(options);
    }
    const loadMoreCost3 = async () => {
        if (!hasMore3 || load3) return;
        setPage(prevPage => prevPage + 1);
        await fetchCost3("", page + 1);
    }
    const fetchCost3 = async (inputValue = "", pageNumber = 1) => {
        if (load3) return;
        setLoad3(true);
        try {
            const params = new URLSearchParams();
            params.append('PageNo', pageNumber || 1);
            params.append('PageSize', 20);
            params.append('Description', inputValue || '');
            const response = await InvapiService.getApiParamater('GET_COST_THREE', `?${params.toString()}`);
            const fetchedCost3 = response.data.result.map(account => ({
                label: account.description,
                value: account,
            }));
            if (fetchedCost3.length < 20) setHasMore3(false);
            setCost3(prevAccounts => {
                const staticNoneOption = { label: 'None', value: null };
                if (pageNumber === 1) {
                    return [staticNoneOption, ...fetchedCost3];
                }
                return [...prevAccounts, ...fetchedCost3];
            });
            setLoad3(false);
            return fetchedCost3;
        } catch (error) {
            console.error("Error fetching account data:", error);
            setLoad3(false);
            return [];
        }
    };
    const loadCost4 = async (inputValue, callback) => {
        const options = await fetchCost4(inputValue, 1);
        callback(options);
    }
    const loadMoreCost4 = async () => {
        if (!hasMore4 || load4) return;
        setPage(prevPage => prevPage + 1);
        await fetchCost4("", page + 1);
    }
    const fetchCost4 = async (inputValue = "", pageNumber = 1) => {
        if (load4) return;
        setLoad4(true);
        try {
            const params = new URLSearchParams();
            params.append('PageNo', pageNumber || 1);
            params.append('PageSize', 20);
            params.append('Description', inputValue || '');
            const response = await InvapiService.getApiParamater('GET_COST_FOUR', `?${params.toString()}`);
            const fetchedCost4 = response.data.result.map(account => ({
                label: account.description,
                value: account,
            }));
            if (fetchedCost4.length < 20) setHasMore4(false);
            setCost4(prevAccounts => {
                const staticNoneOption = { label: 'None', value: null };
                if (pageNumber === 1) {
                    return [staticNoneOption, ...fetchedCost4];
                }
                return [...prevAccounts, ...fetchedCost4];
            });
            setLoad4(false);
            return fetchedCost4;
        } catch (error) {
            console.error("Error fetching account data:", error);
            setLoad4(false);
            return [];
        }
    };
    const loadCustomer = async (inputValue, callback) => {
        const options = await fetchCustomer(inputValue, 1);
        callback(options);
    }
    const loadMoreCustomer = async () => {
        if (!hasMoreCustomer || loaderCustomer) return;
        setPage(prevPage => prevPage + 1);
        await fetchCustomer("", page + 1);
    }
    const fetchCustomer = async (inputValue = "", pageNumber = 1) => {
        if (loaderCustomer) return;
        setLoadCustomer(true);
        try {
            const params = new URLSearchParams();
            params.append('PageNo', pageNumber || 1);
            params.append('PageSize', 20);
            params.append('CustomerName', inputValue || '');
            const response = await apiService.getApiParamater('GET_CUSTOMER', `?${params.toString()}`);
            const fetchedData = response.data.result.map(data => ({
                label: data.customer_Name,
                value: data,
            }));
            if (fetchedData.length < 20) setHasMoreCustomer(false);
            setCustomerList(prevData => {
                if (pageNumber === 1) {
                    return [...fetchedData];
                }
                return [...prevData, ...fetchedData];
            });
            setLoadCustomer(false);
            return fetchedData;
        } catch (error) {
            console.error("Error fetching Customer data:", error);
            setLoadCustomer(false);
            return [];
        }
    };
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'ContactNo' || name === 'customerNum') && value.length > 16) {
            return;
        }
        if (name === 'deliveryDate' || name === 'DocumentDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handlePayChange = (selectedOption) => {
        setPayment(selectedOption)
    }
    const handleVatChange = () => {
        setIsVatExcluded(!isVatExcluded);
    }
    const handleCost1 = (selectedOption) => {
        setFormData({ ...formData, costCenter1: selectedOption });
    }
    const handleCost2 = (selectedOption) => {
        setFormData({ ...formData, costCenter2: selectedOption });
    }
    const handleCost3 = (selectedOption) => {
        setFormData({ ...formData, costCenter3: selectedOption });
    }
    const handleCost4 = (selectedOption) => {
        setFormData({ ...formData, costCenter4: selectedOption });
    }
    const handleCustomer = (selectedOption) => {
        setFormData({
            ...formData,
            customerName: { label: selectedOption?.value.customer_Name, value: selectedOption.value },
            customerId: { label: selectedOption?.value.customer_no, value: selectedOption.value },
            customerNum: selectedOption?.value.mainContactNo
        });
    }
    const CreateSO = async () => {
        setLoader(true)
        if (ItemsData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }
        else if (!formData.customerName?.label) {
            toast.warning("Please Select Customer");
            setLoader(false)
            return;
        }
        const totalAmount = ItemsData.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
        try {
            const currentDatetimeUTC = new Date().toISOString();
            const SODetailsData = ItemsData.map((item, index) => ({
                sOrderNo: SoNum,
                deliveryDate: formData.deliveryDate,
                // status: 0,
                status: 1,
                vatPerc: item?.vat,
                vatDesc: item?.vatDec,
                vatAmount: item.vatAmount,
                vatUnitPrice: item.vatAmount / parseInt(item.qty),
                vatBaseAmount: item.vatAmount,
                quotationNo: formData?.quotationNo || SoNum,
                srno: index + 1,
                rsrno: index + 1,
                itemCode: item.ItemNo || item.recordId,
                sItemCode: item.ItemNo || item.recordId,
                itemDescription: item.itemDescription,
                uom: item.uom,
                qty: parseInt(item.qty),
                specification: item.specification,
                unitPrice: parseFloat(item.unitPrice),
                amount: parseFloat(item.amount),
                baseAmount: parseFloat(item.amount),
                cancelledQty: 0,
                returnQty: 0,
                budgetCode: '',
            }));
            const userData = {
                sOrderNo: SoNum,
                docId: "SO",
                // attention: SupplierContact,
                location: formData?.location,
                currencyCode: SelectedCurrency?.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                department: formData?.Department,
                deliveryPoint: formData?.deliveryPoint,
                contactPerson: formData?.contactPerson,
                // status: 0,
                status: 1,
                contractNo: formData?.customerNum,
                description: formData?.Description,
                modifiedBy: formData.SellerName,
                // modifiedBy: userName,
                remarks: formData?.remarks,
                costCenter1: formData?.costCenter1?.label,
                costCenter2: formData?.costCenter2?.label,
                costCenter3: formData?.costCenter3?.label,
                costCenter4: formData?.costCenter4?.label,
                isActive: true,
                soDetails: SODetailsData,
                amount: totalAmount,
                costCenter5: isVatExcluded === true ? "excluded" : "Included",
                amount: totalAmount,
                baseAmount: totalAmount,
                quotationRef: formData?.QuotationReq,
                quotationNo: formData?.quotationNo || SoNum,
                vendorId: formData.customerId.label,
                vendorName: formData.customerName.label,
                docDate: currentDatetimeUTC,
                deliveryDate: formData.deliveryDate,
                terms: Payment.label,
                aprrovalUser: '',
                modifiedOn: currentDatetimeUTC,
                approvalDate: currentDatetimeUTC,
                soType: "SaleOrder",
                discount: 0,
                discountPerc: 0,
            };
            const response = await apiService.post("CREATE_SALE", userData);
            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "SO",
                    docId: SoNum,
                    department: "IT",
                    // company: Userdata?.company || "Qfinity",
                };
                const AttachmentData = {
                    docType: "Purchase Requisition",
                    docNo: SoNum ? SoNum.toString() : SoNum,
                    fileName: "IT",
                };
                const docBody = {
                    docId: "SO",
                    typeOfDoc: "SO",
                };
                // const attachment = await apiService.post("CREATE_ATTACHMENT", AttachmentData);
                // const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const docRes = await InvapiService.put("UPDATE_DOC_ID", docBody);
                setLoader(false)
                toast.success(`${SoNum} Created successfully`);
                navigate("/Sales/SaleOrder")
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PO:", error);
        }
    }
    const handleReset = () => {
        setFormData({
            PayOrderNo: "",
            remarks: "",
            DocumentDate: getCurrentLocalDatetime(),
            SupplierContact: "",
            deliveryDate: getCurrentLocalDatetime(),
            deliveryPoint: "",
            location: "",
            contactPerson: "",
            quotationNo: "",
            RequisitionType: "",
            ContactNo: "",
            costCenter1: "",
            costCenter2: "",
            costCenter3: "",
            costCenter4: "",
            CostCentre5: "",
            currencyCode: "",
            customerNum: "",
            exchangeRate: "",
            Description: "",
            SellerName: "",
            customerId: "",
            customerName: "",
            OrderType: [],
            paymentTerm: [],
            SupplierDep: [],
        });
        setPayment([])
        setIsVatExcluded(false)
    }
    const handleFileSelect = (event, id) => {
        const file = event.target.files[0];
        if (file) {
            setAttachmentList((prevList) => [
                ...prevList.map((attachment) =>
                    attachment.id === id ? { ...attachment, fileName: file.name, file: file } : attachment
                ),
                { id: Date.now(), fileName: '', remarks: '' },
            ]);
        }
    };
    const handleView = (file) => {
        if (file && file instanceof File) {
            setPreviewImage(URL.createObjectURL(file));
            setPreviewModal(true);
        } else {
            console.error("No valid file to preview");
        }
    };
    const handleDelete = (id) => {
        const updatedList = attachmentList.filter((attachment) => attachment.id !== id);
        setAttachmentList(updatedList);
    };
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Create Sale Order</h5>
                <div className='d-flex gap-3'>
                    {loader === true ?
                        <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                        <button className='mainbtn ' onClick={CreateSO} type="submit"><FaSave className='center mt-1 mx-2' /><div>Save</div></button>
                    }
                    {!itemData ?
                        <button className='mainbtnRed' onClick={handleReset} ><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button> : ""
                    }
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSOTab(!SOTab)}>
                    <div className='h6 pt-1'>Sale Order Details</div>
                </div>
                <div className={`slide-content ${SOTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${SOTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNO" className="form-label center">Sale Order No *</label>
                                <input
                                    className="form-Input w-70"
                                    disabled value={SoNum}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="DeliveryDate" className="form-label center">Delivery Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-Input w-70"
                                    id="DeliveryDate" min={currentDatetimeUTC}
                                    value={formData.deliveryDate}
                                    onChange={handleInputChange}
                                    name="deliveryDate"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Currency" className="form-label center">Currency </label>
                                <Select
                                    isSearchable={false} isDisabled
                                    value={SelectedCurrency}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="DocumentDate" className="form-label center">Document Date</label>
                                <input
                                    value={formData.DocumentDate}
                                    className='form-Input w-70'
                                    id="DocumentDate"
                                    type='datetime-local' min={currentDatetimeUTC}
                                    onChange={handleInputChange}
                                    name="DocumentDate"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryPoint" className="form-label center">Delivery Point</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="deliveryPoint"
                                    name="deliveryPoint"
                                    value={formData?.deliveryPoint}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="ExchangeRate" className="form-label center">Exchange Rate </label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled
                                    id="exchangeRate"
                                    value={formData.exchangeRate}
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="quotationNo" className="form-label center">Quotation No</label>
                                <input
                                    className="form-Input w-70" name='quotationNo'
                                    id="quotationNo" disabled
                                    value={formData.quotationNo}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="location" className="form-label center">Location</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="location"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Description" className="form-label center">Description</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="Description"
                                    name="Description"
                                    value={formData.Description}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="RequisitionType" className="form-label center">Quotation Type</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="RequisitionType"
                                    name="RequisitionType"
                                    value={formData.RequisitionType}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="contactPerson" className="form-label center">Contact Person</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="contactPerson"
                                    name="contactPerson"
                                    value={formData.contactPerson}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Remarks" className="form-label center">Sale Order Type</label>
                                <Select
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="QuotationReq" className="form-label center">Quotation Req</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="QuotationReq" 
                                    name="QuotationReq"
                                    value={formData.QuotationReq}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="ContactNo" className="form-label center">Contact No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="ContactNo"
                                    name="ContactNo"
                                    value={formData.ContactNo}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Description" className="form-label center">Remarks</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="remarks"
                                    name="remarks"
                                    value={formData.remarks}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="SellerName" className="form-label center">Seller Name</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="SellerName"
                                    name="SellerName"
                                    value={formData.SellerName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className="col-2 brderbox center" onClick={() => handleOpen()} >
                                {'Attachment'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setCustomerTab(!CustomerTab)}>
                    <div className='h6 pt-1'>Customer Details</div>
                </div>
                <div className={`slide-content ${CustomerTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row  pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Customer ID</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.customerId}
                                    onChange={handleCustomer}
                                    loadOptions={loadCustomer}
                                    defaultOptions={[
                                        { value: null, label: 'None' },
                                        ...customerList?.map((sup) => ({ value: sup.value, label: sup?.value?.customer_no })),
                                    ]}
                                    cacheOptions
                                    onMenuScrollToBottom={loadMoreCustomer}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Customer Name</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.customerName}
                                    onChange={handleCustomer}
                                    loadOptions={loadCustomer}
                                    defaultOptions={[
                                        { value: null, label: 'None' },
                                        ...customerList?.map((sup) => ({ value: sup.value, label: sup?.value?.customer_Name })),
                                    ]}
                                    cacheOptions
                                    onMenuScrollToBottom={loadMoreCustomer}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Payment terms</label>
                                <Select
                                    className="w-70 p-0"
                                    value={Payment}
                                    // isDisabled={itemData?.vendorName ? true : false}
                                    onChange={(selectedOption) => handlePayChange(selectedOption)}
                                    options={payments?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Customer Dept</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                // isDisabled={itemData?.vendorName ? true : false}
                                // value={formData.SupplierDep}
                                // options={department?.map((dep) => ({ value: dep, label: dep?.description }))}
                                // onChange={(selectedOption) => handleDepChange(selectedOption)}
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="Property" className="form-label center">Customer Contact</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="customerNum"
                                    // disabled={itemData?.vendorName ? true : false}
                                    value={formData.customerNum}
                                    onChange={handleInputChange}
                                    name="customerNum"
                                />
                            </div>
                            <div className="col-4 pt-1 d-flex justify-content-between">
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input
                                            style={{ width: "26px", height: "26px" }}
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isVatExcluded}
                                            onChange={handleVatChange}
                                        />
                                        <label className="form-check-label mx-2 mt-1">
                                            Vat Excluded
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input
                                            style={{ width: "26px", height: "26px" }}
                                            type="checkbox"
                                            className="form-check-input"
                                        // checked={isDiscountPercentage}
                                        // onChange={handleDiscountChange}
                                        />
                                        <label className="form-check-label mx-2 mt-1">
                                            Discount in %
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter1" className="form-label me-2">Property Group</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter1}
                                    onChange={handleCost1}
                                    loadOptions={loadCost1}
                                    defaultOptions={cost1}
                                    cacheOptions
                                    onMenuScrollToBottom={loadMoreCost1}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter2" className="form-label me-2">Property</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter2}
                                    onChange={handleCost2}
                                    loadOptions={loadCost2}
                                    defaultOptions={cost2}
                                    cacheOptions
                                    onMenuScrollToBottom={loadMoreCost2}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter3" className="form-label me-2">Department</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter3}
                                    onChange={handleCost3}
                                    loadOptions={loadCost3}
                                    defaultOptions={cost3}
                                    cacheOptions
                                    onMenuScrollToBottom={loadMoreCost3}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter4" className="form-label me-2">Outlet</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter4}
                                    onChange={handleCost4}
                                    loadOptions={loadCost4}
                                    defaultOptions={cost4}
                                    cacheOptions
                                    onMenuScrollToBottom={loadMoreCost4}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter5" className="form-label me-2">Cost Centre 5</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Terms
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Recommendation
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 mb-3">
                {gridVisibility[0] && (
                    <ItemsGrid onRequisitionData={handleRequisitionData} selectedCheck={isVatExcluded} itemData={itemData} />
                )}
            </div>
            <div>
                <Modal show={attachmentModal} size="lg" onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Attachments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col d-flex justify-content-between">
                            <label htmlFor="docNp" className="form-label  pt-2 start">Document No</label>
                            <input disabled value={SoNum} className="form-Input w-70" />
                        </div>
                        <div className="pt-2">
                            <table>
                                <thead className="dynoTable">
                                    <tr>
                                        <th>File Name</th>
                                        <th>Remarks</th>
                                        <th>Upload</th>
                                        <th>View</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attachmentList?.map((attachment, index) => (
                                        <tr key={attachment.id}>
                                            <td className='m-0 p-0'><input className='p-0' type='text' disabled value={attachment.fileName} style={{ width: "320px" }} /></td>
                                            <td>
                                                <input type="text" value={attachment.remarks}
                                                    onChange={(e) => {
                                                        const updatedList = attachmentList?.map((item) =>
                                                            item.id === attachment.id ? { ...item, remarks: e.target.value } : item
                                                        );
                                                        setAttachmentList(updatedList);
                                                    }}
                                                    style={{ width: '345px' }}
                                                />
                                            </td>
                                            <td>
                                                <button className='mainbtn ' onClick={() => document.getElementById(`fileInput-${attachment.id}`).click()} type="submit"><div>Upload</div></button>
                                                <input
                                                    type="file" accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleFileSelect(e, attachment.id)}
                                                    id={`fileInput-${attachment.id}`}
                                                />
                                            </td>
                                            <td><button className='mainbtnRed ' onClick={() => handleView(attachment.file)} disabled={!attachment.fileName}>
                                                <div>View</div></button></td>
                                            <td>
                                                <button onClick={() => handleDelete(attachment.id)} disabled={!attachment.fileName} className='mainbtnSecondary'>
                                                    <div>Delete</div></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='mainbtn '>Attach</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={previewModal} size="lg" onHide={() => setPreviewModal(false)}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Image Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {previewImage && <img src={previewImage} alt={previewImage} style={{ width: '100%', objectFit: 'cover' }} />}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};
export default CreateSaleOrder; 